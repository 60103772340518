import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { config } from '../Constants'
import { v4 as uuidv4 } from 'uuid';
import { Script } from 'gatsby'

export const AppContext = createContext();

const isBrowser = typeof window !== "undefined"

const deviceStorage = (typeof window !== 'undefined') ? window.localStorage : null

if (isBrowser && !window.sendEvent) {
    window.sendEvent = function (event, subEvent, restaurantId) {
        const customerId = new Cookies().get('_tas_cid') || window._tas_id

        fetch(`${config.url.API}/analytics/event`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ event, subEvent, restaurantId, customerId })
        })
    }
}

function setCookie() {
    if (isBrowser) {
        const cookies = new Cookies();
        var id = (cookies.get('_tas_cid') || window._tas_id) || uuidv4();
        window._tas_id = id
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);
        cookies.set('_tas_cid', id, { path: '/', expires: nextYear });
    }
}

setCookie()

const ContextToSource = (context, utm_medium) => {
    if (context == 'preview')
        return 'preview';
    else if (context == 'landing-page')
        return 'landing-page';
    else if (utm_medium == 'qrcode')
        return 'qrcode';
    return "direct"
}

const pathNameToPage = (pathName) => {
    if (pathName.endsWith("/inventory") || pathName.endsWith("/orders")) return "menu"
    if (pathName.endsWith("/register")) return "contact_tracing"
    return "homepage"
}

function getSearchParams() {
    const searchParams = new URLSearchParams(isBrowser ? window.location.search : "");
    return {
        lang: searchParams.get("lang"),
        source: ContextToSource(searchParams.get("context"), searchParams.get("utm_medium")),
        table: searchParams.get("table"),
        menuId: searchParams.get("menu"),
        utm_medium: searchParams.get("utm_medium"),
        id: searchParams.get("id"),
        page: pathNameToPage(isBrowser ? (window.location?.pathname || "") : "")
    }
}

function resolveAliasLogic({ alias }) {
    return fetch(`${config.url.PUBLIC}/restaurants/${alias}/alias.json`)
        .then(res => {
            if (res.status === 404) return alias

            return res.json().then(data => {
                // alias.json contains {id: RESTAURANT_ID} pointing to the real ID if it was accessed through the alias URL.
                // If it does not contain the "id" field, then it means the URL was not an alias (it was already the real ID).
                return data.id || alias;
            })
        })
        .catch(e => {
            console.error(e);
            return null
        });
}

export const AppProvider = ({ children, location }) => {
    const [restaurantId, setRestaurantId] = useState(null)
    const [metadata, setMetadata] = useState({})
    var restaurantAlias = isBrowser ? window.location.hostname.split('.')[0] : ""
    const searchParams = getSearchParams()
    const [visitRecorded, setVisitRecorded] = useState(false)
    const [fetchingUrl, setFetchingUrl] = useState(false)

    const [defaultMenu, setDefaultMenu] = useState(searchParams?.menuId || deviceStorage?.getItem(`${restaurantAlias}-defaultMenu`) || null)

    if (searchParams["source"] === "preview") { // TODO: block this alias
        restaurantAlias = searchParams["id"]
    }

    if (searchParams?.menuId) {
        deviceStorage?.setItem(`${restaurantAlias}-defaultMenu`, searchParams?.menuId)
    }

    useEffect(() => {
        async function resolveAlias() {
            resolveAliasLogic({ alias: restaurantAlias }).then(setRestaurantId)
        }
        resolveAlias()
    }, [])

    useEffect(() => {
        if (!restaurantId) return

        // Fetch metadata
        fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/metadata.json`)
            .then(res => res.json())
            .then(setMetadata)
            .catch(e => {
                console.error(e)
                setMetadata({})
            })

        // Log the visit
        if (isBrowser && restaurantId && !visitRecorded) {
            setVisitRecorded(true)
            try {
                fetch(`${config.url.API}/analytics/visit`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        customerId: (new Cookies().get('_tas_cid') || "no-cookie"),
                        restaurantId,
                        page: searchParams.page,
                        source: searchParams.source,
                        useragent: navigator?.userAgent
                    })
                })
            } catch (e) {
                console.error("Could not send event ", e)
            }
        }
    }, [restaurantId])

    if (metadata?.blocked) {
        return <div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "100%",
            minHeight: "100vh",
            fontFamily: "sans-serif",
            textAlign: "center",
            padding: "1rem"
        }}>
            <style>{`#root { visibility: visible }`}</style>
            <div>Your free trial has ended.</div>
            <br />
            <div>To activate your account, please pay for your subscription:</div>
            <br />
            <br />
            <div style={{ cursor: "pointer" }} onClick={() => {
                if (!metadata?.id) return alert("There was an issue with your account, please contact the team")

                if (fetchingUrl) return

                setFetchingUrl(true)
                fetch(`${config.url.API}/stripe/public/checkout-session/${metadata?.id}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }).then(resp => {
                    setFetchingUrl(false)

                    if (resp.status === 200) {
                        resp.json().then(data => {
                            window.location = data.url
                        })
                    } else {
                        alert("There was an issue with your account, please contact the team")
                    }
                })
            }}>
                <div style={{
                    padding: "1rem",
                    background: "#2cb052",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "4px"
                }}>{fetchingUrl ? <span>Loading...</span> : <span>Take subscription</span>}</div>
            </div>
            <div style={{
                position: "fixed",
                bottom: "1rem",
                padding: "1rem"
            }}>
                <div>
                    <div>Are you facing an issue? Contact our support team on WhatsApp:</div>
                    <br />
                    <div><a href="https://wa.me/message/DNKIQLTDOCQPI1" rel="me"><b>+41 79 479 34 65</b></a></div>
                </div>
                <br />
                <br />
                <div style={{ color: "grey" }}>Take a Seat ® 2023</div>
            </div>
        </div>
    }

    // TODO: theme.css and script are added twice

    return (
        <AppContext.Provider value={{ restaurantId, restaurantAlias, metadata, isBrowser, searchParams, setMetadata, defaultMenu, setDefaultMenu }}>
            {restaurantId && <link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/theme.css`} />}
            {children}
            {(searchParams["source"] !== "preview" && metadata.ads) && <>
                <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6907665890913731" crossorigin="anonymous"></Script>
                <Script async src="https://fundingchoicesmessages.google.com/i/pub-6907665890913731?ers=1" nonce="XdCkisZ3EchgSNTXWFcAkw"></Script>
            </>}
        </AppContext.Provider>
    );
};